import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { API, Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import { localStorageSave, localStorageGet } from '../utils/localStorageHelper';
import regex from '../utils/regex';
import InputErrorMessage from '../atoms/InputErrorMessage';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import withTracker from '../wrappers/withTracker';
import {
  emailCheckLimitExceededEvent,
  emailAlreadyInUseEvent,
  userEmailSubmitEvent,
} from '../actions/segment';
import { setIsFetching } from '../actions';
import usePatientLeadAPI from '../hooks/usePatientLeadAPI';
import { PATIENT_LEAD_STATUSES } from '../constants/salesforceTypes';
import OshiNextButton from '../components/OshiNextButton';
import { useHistory } from 'react-router-dom';

const USER_EXIST_SUBSCRIBED_MESSAGE = 'User already exists';
const USER_EXIST_NOT_SUBSCRIBED_MESSAGE = 'User already exists not subscribed';
const MASK_PLACE_HOLDER = '    -     -     ';

function Email({
  emailCheckLimitExceededEvent,
  emailAlreadyInUseEvent,
  userEmailSubmitEvent,
  setIsFetching,
}) {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [showErrors, setShowErrors] = useState(null);
  const [userExistsError, setUserExistsError] = useState(null);
  const history = useHistory();
  const [userExistsNotSubscribedError, setUserExistsNotSubscribedError] =
    useState(false);
  const { savePatientLead } = usePatientLeadAPI();
  const email = localStorageGet('email');
  const phoneNumber = localStorageGet('phone');
  const { qstring } = localStorageGet('qstring');
  const emailRef = useRef();
  const phoneRef = useRef();

  function emailValidation(email) {
    const errors = {};
    const errorMessage = 'Enter a valid email address.';

    if (email.length < 1 || !regex.email.test(email)) {
      errors.email = errorMessage;
      return errors;
    }

    return errors;
  }

  function phoneNumberValidation(phoneNumber) {
    // masked phone coming in 'xxx - xxx - xxxx'
    // regex can only match 'xxx-xxx-xxxx'
    // so we have to remove the spaces
    const splitPhoneNum = phoneNumber.split(' - ').join('-');

    // Phone number will now be ['X','X','X','X','X','X','X','X',]
    const phoneNumberNumbersOnlyArray = phoneNumber
      .split(' - ')
      .join('')
      .split('');
    const errors = {};

    // make sure it passes regex validation
    // make sure first item in phone num array has no 1 or 0
    if (
      !splitPhoneNum.match(regex.phone) ||
      phoneNumberNumbersOnlyArray[0] === '0' ||
      phoneNumberNumbersOnlyArray[0] === '1'
    ) {
      errors.phoneNumber = 'Enter a valid phone number';
      return errors;
    }

    return errors;
  }

  function validate({ email, phoneNumber }) {
    let errors = {};

    const phoneNumberErrors = phoneNumberValidation(phoneNumber);
    const emailErrors = emailValidation(email);
    errors = { ...errors, ...phoneNumberErrors, ...emailErrors };
    return errors;
  }

  const formik = useFormik({
    initialStatus: { existingValues: email, phoneNumber },
    initialValues: {
      email: email || '',
      phoneNumber: phoneNumber || '',
    },
    validate,
    validateOnMount: !!email || !!phoneNumber,
    onSubmit: () => handleNext(),
  });
  const { firstName, lastName } = localStorageGet('name');
  const { partner } = localStorageGet('partner');

  function clearParentErrorOnFocus() {
    setShowErrors(null);
    setUserExistsError(null);
    setUserExistsNotSubscribedError(null);
  }

  async function checkIfUserExists() {
    /*
      We always expect this function to fail and go into
      the catch method. It's a hack to check and see if the user already exists
    */
    const userEmail = formik.values.email.toLowerCase();
    const code = '000000';

    setIsFetching(true);

    const [confirmSignUp] = await Promise.all([
      new Promise((resolve) =>
        Auth.confirmSignUp(userEmail, code, {
          // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
          forceAliasCreation: false,
        })
          .then((response) => resolve({ success: response }))
          .catch((error) => resolve({ error }))
      ),
    ]);

    if (confirmSignUp.success) return false;

    if (confirmSignUp.error) {
      switch (confirmSignUp.error.code) {
        case 'UserNotFoundException':
        case 'ExpiredCodeException':
        case 'CodeMismatchException':
          return false;
        case 'LimitExceededException': {
          const errorMessage =
            'Sorry, you have exceeded the maximum number of submission attempts, please try again later';
          emailCheckLimitExceededEvent(userEmail);
          // We dont want to let the user
          // continue if they tried way too many
          // times
          formik.setFieldError('email', errorMessage);
          setShowErrors(errorMessage);
          setIsFetching(false);
          return true;
        }
        case 'AliasExistsException':
        case 'NotAuthorizedException':
          // Email alias already exists
          formik.setFieldError('email', USER_EXIST_SUBSCRIBED_MESSAGE);
          emailAlreadyInUseEvent();
          let onboardingAppoitmentStatus;
          try {
            onboardingAppoitmentStatus = await API.get(
              'oshiAPI',
              `/users/onboarded?email=${encodeURIComponent(userEmail)}`
            );
          } catch (error) {
            console.log('error getting onboarding appointment status', error);
            setIsFetching(false);
          }

          setUserExistsError(onboardingAppoitmentStatus.onboarded);
          setIsFetching(false);
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  async function handleNext() {
    // we need to clear the existing user errors so that
    // when they hit the submit button it clears the error
    if (
      (userExistsError &&
        formik.errors.email === USER_EXIST_SUBSCRIBED_MESSAGE) ||
      (userExistsError &&
        formik.errors.email === USER_EXIST_NOT_SUBSCRIBED_MESSAGE)
    ) {
      formik.setFieldError('email', null);
      setUserExistsError(false);
      setUserExistsNotSubscribedError(false);
    }

    // errorsArray will only have a single
    // element so we can  use [0]
    // if there are errors we get the message from
    // that error coming from Formik and set it as the error
    const errorsArray = Object.keys(formik.errors);

    if (
      errorsArray.length > 0 &&
      formik.errors.email !== USER_EXIST_SUBSCRIBED_MESSAGE
    ) {
      return setShowErrors(formik.errors[errorsArray[0]]);
    }
    const doesUserExist = await checkIfUserExists();
    localStorageSave('email', formik.values.email);
    localStorageSave('phone', formik.values.phoneNumber);
    if (doesUserExist) return;

    // save SF lead
    savePatientLead('create', {
      first_name: firstName,
      last_name: lastName,
      partner,
      qstring,
      email: formik.values.email,
      status: PATIENT_LEAD_STATUSES.NEW,
      phone_number: formik.values.phoneNumber.replace(/[^A-Z0-9]/gi, ''),
    });

    userEmailSubmitEvent();
    // animate and transition
    setAnimationDirection('unmount');
    return setIsFetching(false);
  }

  function sendUserToSignIn() {
    history.push('/signin');
  }

  function sendToOshiAppDownloadLink() {
    window.location.href = 'https://oshihealth.com/deeplink/';
  }

  function handleMailToClick() {
    window.location.href = 'mailto:carecoordinator@oshihealth.com';
  }

  const handleKeyPress = (evt) => {
    const hasHitEnterKey = evt.keyCode === 13;
    const errorsKeysArray = Object.keys(formik.errors);
    const { email: emailValue } = formik.values;

    (showErrors || userExistsError) && clearParentErrorOnFocus();

    if (hasHitEnterKey) {
      evt.preventDefault();
    }

    if (formik.errors.email && hasHitEnterKey) {
      emailRef.current.blur();
      setShowErrors(formik.errors[errorsKeysArray[0]]);
    }

    if ((formik.dirty || emailValue) && errorsKeysArray.length === 0) {
      hasHitEnterKey && handleNext();
      setShowErrors(null);
    }
  };

  function getUserExistsErrorText() {
    if (userExistsError === 'none') {
      return (
        <span>
          Looks like you already have an account.{' '}
          <DownloadAppLink
            role='link'
            onKeyPress={sendUserToSignIn}
            tabIndex={0}
            onClick={sendUserToSignIn}
          >
            Sign in
          </DownloadAppLink>{' '}
          now to book your appointment.
        </span>
      );
    }
    return (
      <span>
        Looks like you already have an account with us. Please open your Oshi
        Health mobile app or{' '}
        <DownloadAppLink
          role='link'
          onKeyPress={sendToOshiAppDownloadLink}
          tabIndex={0}
          onClick={sendToOshiAppDownloadLink}
        >
          download it
        </DownloadAppLink>{' '}
        now to continue
      </span>
    );
  }

  return (
    <AnimatedRoute
      nextRoute='/gender'
      title='Email & phone number'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form onKeyDown={handleKeyPress}>
        <OshiInput
          id='email'
          ref={emailRef}
          autoFocus
          autoFill='on'
          type='text'
          label='Email (App Username)'
          onChange={formik.handleChange}
          formik={formik}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
          error={(showErrors && formik.errors.email) || userExistsError}
          segmentLabel='Sign Up - Email'
          value={formik.values.email}
          showCheckMark
          isValid={formik.dirty && !formik.errors.email}
        />

        {showErrors && formik.errors.email && (
          <ErrorContainer>
            <span>{formik.errors.email}</span>
          </ErrorContainer>
        )}

        <OshiInput
          id='phoneNumber'
          ref={phoneRef}
          onSelect={() => {
            if (formik.values.phoneNumber === MASK_PLACE_HOLDER) {
              phoneRef.current.setSelectionRange(0, 0);
            }
          }}
          type='tel'
          pattern='\d*'
          label='Mobile Number'
          isValid={!formik.errors.phoneNumber}
          maskPlaceholder={MASK_PLACE_HOLDER}
          onChange={formik.handleChange}
          segmentLabel='Patient Info - Phone Number'
          showCheckMark
          formik={formik}
          value={formik.values.phoneNumber}
          mask='999 - 999 - 9999'
          error={showErrors && formik.errors.phoneNumber}
          clearParentErrorOnFocus={clearParentErrorOnFocus}
        />

        <ErrorsContainer>
          <InputErrorMessage
            formik={formik}
            value={formik.values.phoneNumber}
            isValid={!formik.errors.phoneNumber}
            error='XXX-XXX-XXXX'
            showCheckMark
          />
        </ErrorsContainer>

        {showErrors && formik.errors.phoneNumber && (
          <ErrorContainer>
            <span style={{ marginTop: '12px' }}>
              {formik.errors.phoneNumber}
            </span>
          </ErrorContainer>
        )}

        {(userExistsError || userExistsNotSubscribedError) && (
          <ErrorContainer
            style={{
              paddingTop: '40px',
            }}
          >
            {userExistsError && getUserExistsErrorText()}

            {userExistsNotSubscribedError && (
              <Typography styles={{ color: palette.error }}>
                Please contact a{' '}
                <DownloadAppLink
                  role='link'
                  onKeyPress={handleMailToClick}
                  tabIndex={0}
                  onClick={handleMailToClick}
                >
                  care coordinator
                </DownloadAppLink>{' '}
                to finish signing up
              </Typography>
            )}
          </ErrorContainer>
        )}
        <SubcopyContainer>
          <p>
            By entering your email and mobile number you are agreeing that Oshi
            Health may contact you for marketing and care plan communication
            purposes. You may opt out of receiving these marketing messages at
            any time. For further information, please refer to the Oshi
            Health&nbsp;
            <a target='_blank' href='https://oshihealth.com/privacy-policy/' rel='noopener noreferrer'>
              privacy policy
            </a>
            .
          </p>
        </SubcopyContainer>
      </Form>

      <OshiNextButton
        // eslint-disable-next-line
        disabled={
          (!formik.dirty && !formik.initialStatus.existingValues) ||
          Object.keys(formik.errors).length > 0
        }
        onClick={handleNext}
        buttonTitle='Continue'
      />
    </AnimatedRoute>
  );
}

const Form = styled.form``;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-bottom: 20px;

  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /* or 19px */

  letter-spacing: 0.05em;
  font-feature-settings: 'liga' off;

  /* error / error-500 */
  color: ${palette.error};
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubcopyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0px 50px 0px;
  letter-spacing: 0.04em;
  @media only screen and (min-width: 429px) {
    margin: 12px 0px 0px 0px;
  }

  font-family: 'Usual';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 168%;
  /* or 20px */

  letter-spacing: 0.04em;
  font-feature-settings: 'liga' off;

  color: ${palette.coolGray};
`;

const DownloadAppLink = styled.span`
  font-weight: bold;
  color: ${palette.navy};
  cursor: pointer;
  text-decoration: underline;
`;

export default connect(null, {
  emailCheckLimitExceededEvent,
  emailAlreadyInUseEvent,
  userEmailSubmitEvent,
  setIsFetching,
})(withTracker(Email, 'Signup - Contact Information - Page View'));
