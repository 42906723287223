export const NEW_SUBSCRIPTION_STATUS = 'new';
export const SUBSCRIBED_SUBSCRIPTION_STATUS = 'subscribed';
export const INSURED_SUBSCRIPTION_STATUS = 'insured';
export const OPTUM_SUBSCRIPTION_STATUS = 'optum';
export const INSURED_FLOW = [
  INSURED_SUBSCRIPTION_STATUS,
  OPTUM_SUBSCRIPTION_STATUS,
];

export const PARTNERS_TYPE = {
  PARTNER: 'Partner',
  PAYER: 'Payer',
  HEALTH_PLAN: 'Health Plan',
  REFERRING_PROVIDER: 'Referring Provider',
  EMPLOYER: 'Employer',
};

export const CURRENT_PARTNERS = {
  AEO: 'aeo',
  AETNA: 'aetna',
  UHC_OXFORD: 'uhc_oxford',
  UHC: 'uhc',
  OTHER: 'other',
  BLUE_CROSS: 'bluecrossma',
  FIREFLY: 'firefly',
  MARINER: 'mariner',
  CHILDRENS: 'childrens',
  RGS: 'rgs',
  TE: 'te',
  AUTOLIV: 'autoliv',
  FUJIFILM: 'fujifilm',
  CCHMC: 'cchmc',
  MARPAI: 'marpai',
  SOLERA: 'solera',
  SUREST: 'surest',
  SELFPAY: 'selfpay',
  MEMBERSHIP: 'membership',
  SYSCO: 'sysco',
  WOOD: 'wood',
  JACK_HENRY: 'jackhenry',
  LENNOX: 'lennox',
  CERIDIAN: 'ceridian',
  FRONTIER: 'frontier',
  CROWNCASTLE: 'crowncastle',
};

export const TWENTYFOUR_HOURS_PARTNERS = [CURRENT_PARTNERS.UHC];

export const LIST_OF_CURRENT_PARTNERS = Object.keys(CURRENT_PARTNERS).map(
  (value) => CURRENT_PARTNERS[value]
);

export const FEDERAL_PARTNERS = {
  MEDICARE_MEDICAID_MASSHEALTH: 'medicare_medicaid_masshealth',
};

export const PARTNERS_BY_ORDER = [
  CURRENT_PARTNERS.AETNA,
  CURRENT_PARTNERS.UHC_OXFORD,
  CURRENT_PARTNERS.UHC,
  CURRENT_PARTNERS.BLUE_CROSS,
  CURRENT_PARTNERS.FIREFLY,
  CURRENT_PARTNERS.SUREST,
  CURRENT_PARTNERS.OTHER,
];

export const ZERO_COST_PARTNERS = [
  CURRENT_PARTNERS.CHILDRENS,
  CURRENT_PARTNERS.MARINER,
  CURRENT_PARTNERS.RGS,
  CURRENT_PARTNERS.TE,
  CURRENT_PARTNERS.AUTOLIV,
  CURRENT_PARTNERS.FUJIFILM,
  CURRENT_PARTNERS.CCHMC,
  CURRENT_PARTNERS.FIREFLY,
  CURRENT_PARTNERS.WOOD,
  CURRENT_PARTNERS.JACK_HENRY,
  CURRENT_PARTNERS.LENNOX,
  CURRENT_PARTNERS.SOLERA,
  CURRENT_PARTNERS.CERIDIAN,
  CURRENT_PARTNERS.FRONTIER,
  CURRENT_PARTNERS.CROWNCASTLE,
];
