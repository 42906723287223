import {
  CURRENT_PARTNERS,
  PARTNERS_BY_ORDER,
} from '../constants/subscriptionStatus';
import { localStorageGet } from '../utils/localStorageHelper';

/**
 * @function useHealthPlanPageUILogic
 * @description Custom hook to handle customized UI logic operations for new health UI page
 * These operations are contained in functions that, for instance, parses partner list data
 * to display in this UI page. Any UI logic related to this page shall be implemented here.
 * @returns {{
 *   parseList: PartnerUI[],
 * }}
 */
const useHealthPlanPageUILogic = ({ isFirstHealthPlanPartnerById }) => {
  const { isReferringProvider } = localStorageGet('isReferringProvider');
  const getOrderNumberByPartnerId = (partnerId) => {
    return PARTNERS_BY_ORDER.findIndex((p) => p === partnerId) + 1;
  };
  const parseList = (list) => {
    const newList = list
      ?.filter(
        (p) =>
          isFirstHealthPlanPartnerById(p.system_partner_id) &&
          p.type === 'Health Plan'
      )
      ?.map((p) => {
        return {
          ...p,
          order: getOrderNumberByPartnerId(p.system_partner_id),
        };
      });
    newList.forEach((p) => {
      if (p?.system_partner_id === 'uhc') {
        newList.push({
          ...p,
          display_name: CURRENT_PARTNERS.UHC_OXFORD,
          order: getOrderNumberByPartnerId(CURRENT_PARTNERS.UHC_OXFORD),
          system_partner_id: CURRENT_PARTNERS.UHC_OXFORD,
          name: CURRENT_PARTNERS.UHC_OXFORD,
        });
      }
    });
    newList.length > 0 &&
      !isReferringProvider &&
      newList.push({
        type: 'Partner',
        order: getOrderNumberByPartnerId(CURRENT_PARTNERS.OTHER),
        system_partner_id: CURRENT_PARTNERS.OTHER,
        name: 'Other',
        operatingStates: [],
        is_active: true,
        display_name: null,
        partner_id: null,
      });
    newList.sort((a, b) => a.order - b.order);
    return newList.map((item) => {
      return {
        id: `partner-${item.system_partner_id}`,
        value: item.system_partner_id,
        children: item.system_partner_id,
        item,
        tabIndex: 0,
        checked: false,
      };
    }, []);
  };

  return {
    parseList,
  };
};

export default useHealthPlanPageUILogic;
